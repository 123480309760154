import logo from "./images/logo.png";
import "./App.css";
import MailchimpSubscribe from "react-mailchimp-subscribe";
import { SocialIcon } from "react-social-icons";

const url = process.env.REACT_APP_MC;
const SimpleForm = () => <MailchimpSubscribe url={url} />;

function App() {
  return (
    <div className="App">
      <header className="App-header">
        <img src={logo} className="App-logo" alt="logo" />
        <p>
          The #1 Mentoring service for Email Professionals is getting a new lick
          of paint. Pre register below and be the first to hear when our brand
          new product launches!
        </p>
        <MailchimpSubscribe
          url={url}
          render={({ subscribe, status, message }) => (
            <div>
              <SimpleForm onSubmitted={formData => subscribe(formData)} />
              {status === "sending" && (
                <div style={{ color: "#F2C94C" }}>Pre registering...</div>
              )}
              {status === "error" && (
                <div
                  style={{ color: "#F2C94C" }}
                  dangerouslySetInnerHTML={{ __html: message }}
                />
              )}
              {status === "success" && (
                <div style={{ color: "#F2C94C" }}>Pre registered!</div>
              )}
            </div>
          )}
        />
        <div className="socials">
          <SocialIcon
            url="https://twitter.com/Email_Mentor"
            network="twitter"
            bgColor="#fff"
            style={{ marginRight: "10px", height: 35, width: 35 }}
          />
          <SocialIcon
            url="https://www.instagram.com/email.mentor/"
            network="instagram"
            bgColor="#fff"
            style={{ marginRight: "10px", height: 35, width: 35 }}
          />
          <SocialIcon
            url="mailto:contact@email-mentor.com"
            network="email"
            bgColor="#fff"
            style={{ height: 35, width: 35 }}
          />
        </div>
      </header>
    </div>
  );
}

export default App;
